import React, { useState } from "react";
import CertificationContainer from "./CertificationContainer";
import SocialKakao from "../api/kakaoLoginApi";
import SocialGoogle from "../api/googleLoginApi";
import { ErrorOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [viewQR, setViewQR] = useState(false);

  const privacyClick = () => {
    navigate("/privacyPolicyNotice");
  };

  const viewLoginQR = () => {
    setViewQR(true);
  };

  const closeQR = () => {
    setViewQR(false); // QR 이미지를 숨김
  };

  return (
    <div>
      <CertificationContainer />
      <div className="w-full max-w-md p-6 mx-auto bg-white rounded-lg shadow-xl">
        <h2 className="mb-4 text-2xl font-bold text-gray-800 text-center">
          로그인 방식을 선택하세요.
        </h2>
        <div className="flex">
          <SocialKakao />
        </div>
        <div
          // className="mt-1 text-center cursor-pointer text-lg font-semibold hover:text-blue-600 focus:text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded text-gray-800"
          className="text-center cursor-pointer text-lg font-semibold text-white bg-blue-500 hover:bg-blue-400 px-6 py-2 rounded-lg transition duration-300 ease-in-out ml-10 mr-10 mt-5"
          onClick={viewLoginQR}
        >
          핸드폰으로 설문조사 시작하기
        </div>
        {viewQR && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white p-4 rounded shadow-lg">
              {/* 닫기 버튼 */}
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-red-500 text-2xl font-bold"
                onClick={closeQR}
              >
                &times;
              </button>

              {/* QR 이미지 */}
              <img
                className="h-64 w-64 mx-auto"
                src={`${process.env.PUBLIC_URL}/imageQR_return.png`}
                alt="QR Code"
              />
              <p className="text-center mt-4 text-gray-700 font-medium">
                QR 코드를 스캔하여 설문조사를 시작하세요.
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="text-center mt-4 text-blue-800 flex items-center justify-center relative">
        <ErrorOutline
          className="text-blue-800 mr-2 cursor-pointer hover:text-blue-600 focus:text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
          style={{ fontSize: "20px", verticalAlign: "middle" }}
          onClick={privacyClick}
        />
        <div
          className="mt-1 cursor-pointer hover:text-blue-600 focus:text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
          onClick={privacyClick}
        >
          개인 정보 처리 방침
        </div>
      </div>
    </div>
  );
};

export default Login;
